import LocalParkingIcon from '@mui/icons-material/LocalParking';

import { Box, Typography, Grid } from '@mui/material';

export type ParkingSpaceHeaderProps = {
  occupiedByVehicle: boolean;
  message: string;
  backgroundColor: string;
};

const ParkingSpaceHeader = ({
                              occupiedByVehicle = true,
                              backgroundColor,
                              message
}: ParkingSpaceHeaderProps) => {
  return (
    <Grid
      container
      marginTop="30px"
      justifyContent="center"
      display="grid"
      gridTemplateColumns={occupiedByVehicle ? 'auto auto' : '1fr'}
    >
      <Grid item display="flex" justifyContent="center">
        <Box
          sx={{
            width: '120px',
            height: '120px',
            backgroundColor: backgroundColor,
            borderRadius: '12px',
            display: 'grid',
            placeContent: 'center',
            fontSize: '80px',
            color: '#ffffff',
          }}
        >
          <LocalParkingIcon fontSize="inherit" color="inherit" />
        </Box>
      </Grid>
      <br/>
      <Grid item>
        <Typography
          fontWeight={800}
          color={backgroundColor}
          fontSize={'40px'}
          component="h3"
          data-testid="parking-space-title">
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ParkingSpaceHeader;
