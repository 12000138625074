import 'isomorphic-fetch';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Headers } from './types';
import { getConfig } from '../../constants';
import { sec } from './security';

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, WebApi, extraOptions) => {
  const baseUrl = `${getConfig().URL}`;
  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      let accessToken = null;
      if (!getConfig().IS_PLAYWRIGHT) {
        accessToken = await sec.getAccessTokenSilently()();
      }
      if (accessToken) {
        headers.set('Authorization', `${sec.tokenType} ${accessToken}`);
      }
      headers.set(Headers.Accept, 'application/json');
      return headers;
    },
  });
  return rawBaseQuery(args, WebApi, extraOptions);
};

export const apiBase = createApi({
  reducerPath: 'api',
  baseQuery: dynamicBaseQuery,
  // The "endpoints" represent operations and requests for this server
  endpoints: () => ({}),
});
