import ParkingSpaceProgress from '../../Shared/ParkingSpace/ParkingSpaceProgress';
import ParkingSpaceFooter from '../../Shared/ParkingSpace/ParkingSpaceFooter';
import ParkingSpaceTimer from '../../Shared/ParkingSpace/ParkingSpaceTimer';
import ParkingSpaceHeader from '../ParkingSpaceHeader';

import { Grid } from '@mui/material';
import React, { useEffect } from "react";
import { getUntilHour } from "../../../functions";

export type NoParkingAppProps = {
  occupiedByVehicle: boolean;
  remainingTime: number;
  maximumTime: number;
  imageUrl?: string;
  slot: string;
  zone: string;
};

const NoParkingApp = ({
  occupiedByVehicle = false,
  imageUrl = undefined,
  remainingTime,
  maximumTime,
  slot,
  zone,
}: NoParkingAppProps) => {
  const [message, setMessage] = React.useState('No Parking Anytime');
  useEffect(() => {
    // Set the value on mount.
    setMessage(
      occupiedByVehicle ?
        "NO PARKING ZONE":
        remainingTime > 0 ?
          "No Parking until "+ getUntilHour(remainingTime):
          "No Parking Anytime");
  }, [message]);

  const red = '#FF0000';

  return (
    <Grid
      data-testid="parking-space-container"
      display="flex"
      justifyContent="space-around"
      flexDirection="column"
      alignItems="center"
      height="100vh"
      spacing="0"
      container
    >
      <ParkingSpaceHeader
        message={occupiedByVehicle ? 'VIOLATION':''}
        occupiedByVehicle={false}
        backgroundColor={red}/>
      <ParkingSpaceProgress
        showViolationInstantly={true}
        isEmpty={false}
        remainingTime={0}
        maximumTime={0}
        imageUrl={imageUrl}
      />
      <ParkingSpaceTimer
        color={red}
        showViolationInstantly={true}
        message={message}
        isEmpty={!occupiedByVehicle}
        remainingTime={remainingTime}
        maximumTime={maximumTime}
        showTimer={false}
      />
      <ParkingSpaceFooter slot={slot} zone={zone} color={red} />
    </Grid>
  );
};

export default NoParkingApp;
