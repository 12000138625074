export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

export function getDurationTime(ms: number): string | null {
  ms = Math.abs(ms);
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const seconds = Math.floor(minutesms / 1000);
  let finalString = '';
  if (days != 0) {
    finalString = `${days} ${days == 1 ? 'day' : 'days'}`;
  }
  return `${finalString} ${padTo2Digits(hours)}:${padTo2Digits(
    minutes,
  )}:${padTo2Digits(seconds)}`;
}
export const getUntilHour = (remainingTime: number) => new Date(
  new Date().getTime()+ (remainingTime / 60 * 1000)
).toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: 'numeric'
});

export const getParkingTimerLabel = ({
  remainingTime= 0,
  showViolationInstantly = false,
}) => {
  return remainingTime > 0 && !showViolationInstantly ? 'Time Remaining' : 'WARNING!';
};
