import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

import { Box, Typography, Grid } from '@mui/material';

export type ParkingSpaceHeaderRideshareProps = {
  occupiedByVehicle: boolean;
  remainingTime: number;
  maximumTime: number;
  showViolationInstantly?: boolean;
  backgroundColor: string;
};



const ParkingSpaceHeaderRideshare = ({
  backgroundColor,
  showViolationInstantly = false,
  remainingTime,
  maximumTime,
  occupiedByVehicle = false
}: ParkingSpaceHeaderRideshareProps) => {

  const getHeaderText = (
  ) => {
    if (((remainingTime > 0) && !showViolationInstantly) || !occupiedByVehicle)
      return `Loading Zone Maximum ${maximumTime / (60 * 1000)} minutes`;

    if (((remainingTime <= 0) || showViolationInstantly) && occupiedByVehicle) return 'In Violation';
  };
  return (
    <Grid
      container
      marginTop="30px"
      justifyContent="center"
      display="grid"
      gridTemplateColumns={'1fr'}
    >
      <Grid item display="flex" justifyContent="center" position="relative">
      <Box
          sx={{
            width: '120px',
            height: '120px',
            backgroundColor: backgroundColor,
            borderRadius: '12px',
            display: 'grid',
            placeContent: 'center',
            fontSize: '80px',
            color: '#ffffff',
          }}
        >
          <LocalParkingIcon fontSize="inherit" color="inherit" />
        </Box>
      </Grid>
      <Grid item>
        <Typography
          fontWeight={800}
          color="#0030c5"
          fontSize={'50px'}
          component="h3"
          data-testid="parking-space-rideshare-title"
          paddingLeft={'20px'}
          paddingRight={'20px'}
        >
          {getHeaderText()}
          <div
            style={{
              borderBottom: '4px solid #c31500',
              display: 'flex',
              position: 'relative',
              width: '350px',
              margin: '0 auto',
              fontSize: '35px',
              color: '#c31500',
              marginBottom: '8px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-20px',
                right: 'calc(100% - 30px)',
              }}
            >
              <WestIcon fontSize="inherit" color="inherit" />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '-20px',
                left: 'calc(100% - 30px)',
              }}
            >
              <EastIcon fontSize="inherit" color="inherit" />
            </div>
          </div>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ParkingSpaceHeaderRideshare;
