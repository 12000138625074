import ParkingSpaceProgress from '../../Shared/ParkingSpace/ParkingSpaceProgress';
import ParkingSpaceFooter from '../../Shared/ParkingSpace/ParkingSpaceFooter';
import ParkingSpaceTimer from '../../Shared/ParkingSpace/ParkingSpaceTimer';
import ParkingSpaceHeaderRideshare from '../ParkingSpaceHeaderRideshare';

import { Grid } from '@mui/material';
import { getParkingTimerLabel } from "../../../functions";

export type RideshareLoadingAppProps = {
  occupiedByVehicle: boolean;
  remainingTime: number;
  isEmpty: boolean;
  maximumTime: number;
  imageUrl?: string;
  slot: string;
  zone: string;
  isAvailableToEnterZone: boolean;
};

const RideshareLoadingApp = ({
  occupiedByVehicle = false,
  imageUrl = undefined,
  isEmpty = true,
  remainingTime,
  maximumTime,
  slot,
  zone,
  isAvailableToEnterZone,
}: RideshareLoadingAppProps) => {
  const blue = "#0030c5";
  const showViolationInstantly = !isAvailableToEnterZone && occupiedByVehicle;
  return (
    <Grid
      data-testid="rideshare-parking-space-container"
      display="flex"
      justifyContent="space-around"
      flexDirection="column"
      alignItems="center"
      height="100vh"
      spacing="0"
      container
    >
      <ParkingSpaceHeaderRideshare
        backgroundColor={'#3d5bfe'}
        occupiedByVehicle={occupiedByVehicle}
        remainingTime={remainingTime}
        maximumTime={maximumTime}
        showViolationInstantly={showViolationInstantly}
      />
      <ParkingSpaceProgress
        isEmpty={isEmpty}
        remainingTime={remainingTime}
        maximumTime={maximumTime}
        imageUrl={imageUrl}
        showViolationInstantly={showViolationInstantly}
      />
      <ParkingSpaceTimer
        message={getParkingTimerLabel({ remainingTime, showViolationInstantly })}
        isEmpty={isEmpty}
        remainingTime={remainingTime}
        maximumTime={maximumTime}
        showViolationInstantly={showViolationInstantly}
        color={blue}
      />

      <ParkingSpaceFooter slot={slot} zone={zone} color={blue} />
    </Grid>
  );
};

export default RideshareLoadingApp;
