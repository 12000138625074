import { circularProgressClasses } from '@mui/material/CircularProgress';
import { Box, CircularProgress } from '@mui/material';

export type ParkingSpaceProgressProps = {
  isEmpty: boolean;
  remainingTime: number;
  maximumTime: number;
  imageUrl?: string;
  showViolationInstantly?: boolean;
};

const getStatusColor = (
  isEmpty: boolean,
  remainingTime: number,
  maximumTime: number,
  showViolationInstantly: boolean,
) => {
  if (isEmpty) {
    return '#454e7e';
  } else if (remainingTime <= 0 || showViolationInstantly) {
    return '#c31500';
  } else if (remainingTime > 0 && (remainingTime / maximumTime) * 100 <= 10) {
    return '#e4c127';
  }

  return '#90c845';
};

const ParkingSpaceProgress = ({
  showViolationInstantly = false,
  imageUrl = undefined,
  isEmpty = true,
  remainingTime,
  maximumTime,
}: ParkingSpaceProgressProps) => {
  return (
    <>
      <Box
        position="relative"
        flexShrink="0"
        height="400px"
        width="400px"
        borderRadius="50%"
        overflow="hidden"
      >
        <Box
          color={getStatusColor(
            isEmpty,
            remainingTime,
            maximumTime,
            showViolationInstantly,
          )}
          zIndex={2}
          data-testid="parking-space-progress-box"
        >
          <CircularProgress
            variant="determinate"
            value={isEmpty ? 100 : 100 - (remainingTime / maximumTime) * 100}
            size="100%"
            thickness={2}
            color="inherit"
            sx={{
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
              aspectRatio: '1',
            }}
            data-testid="parking-space-progress"
          />
          {showViolationInstantly &&
            <Box
              sx={{
                width: '20px',
                height: '380px',
                backgroundColor: '#c31500',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%) rotate(-40deg)',
                zIndex: 99
              }}
            />
          }{' '}
        </Box>
        <Box
          color={getStatusColor(
            isEmpty,
            remainingTime,
            maximumTime,
            showViolationInstantly,
          )}
        >
          {imageUrl && (
            <img
              src={imageUrl}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: 'calc(100% - 2.2rem)',
                width: 'calc(100% - 2.2rem)',
                zIndex: '1',
                borderRadius: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ParkingSpaceProgress;
