import { ParkingSpaceStatus } from './enums';

export interface RouteType {
  path: string;
  element: React.FunctionComponent;
  title: string;
  exact?: boolean;
  index?: boolean;
}

export type ParkingVehicle = {
  arrived_at: string;
  photo: string;
  licensePlate: string;
  type: string;
};

export type ParkingMessage = {
  photo: string[];
  messages: string[];
};

export type ParkingSpace = {
  uuid: string;
  state: ParkingSpaceStatus;
  remaining_time: number;
  vehicle?: ParkingVehicle;
  message?: ParkingMessage;
};
export interface ParkingSpaceInformation {
  state: ParkingSpaceInformationState;
  max_usage_time: number;
  remaining_time: number;
  vehicle?: {
    arrived_at: Date;
    photo: string;
    licensePlate: string;
    type: string;
  };
  space_label: string;
  zone: string;
  message?: {
    photos: Array<string>;
    messages: Array<string>;
  };
}

export enum ParkingSpaceInformationState {
  NO_PARKING = 'NO_PARKING',
  TIMED_PARKING = 'TIMED_PARKING',
  MESSAGE = 'MESSAGE',
  RIDE_SHARE = 'RIDE_SHARE',
}

export enum ParkingSpaceInformationVehicleType {
  NORMAL = 'NORMAL',
  TAXI = 'TAXI',
  RIDESHARE = 'RIDESHARE',
  COMMERCIAL = 'COMMERCIAL',
}
