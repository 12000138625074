/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import './index.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import './i18n/i18n';
import ErrorPage from './components/Shared/ErrorPage';
import { Provider } from 'react-redux';
import { store } from './app/store';
import ParkingSpacePage from './pages/ParkingSpacePage';

function startApp() {
  const container = document.getElementById('root')!;
  const root = createRoot(container);

  const router = createBrowserRouter([
    ...createRoutesFromElements(
      <Route path="/" element={<App />} errorElement={<ErrorPage />}>
        <Route path="parking/:uuid" element={<ParkingSpacePage />} />
      </Route>,
    ),
  ]);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>,
  );
}

startApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
