import ParkingSpaceProgress from '../../Shared/ParkingSpace/ParkingSpaceProgress';
import ParkingSpaceFooter from '../../Shared/ParkingSpace/ParkingSpaceFooter';
import ParkingSpaceTimer from '../../Shared/ParkingSpace/ParkingSpaceTimer';
import ParkingSpaceHeader from '../ParkingSpaceHeader';

import { Grid } from '@mui/material';
import { getParkingTimerLabel } from "../../../functions";

export type DefaultParkingAppProps = {
  occupiedByVehicle: boolean;
  remainingTime: number;
  isEmpty: boolean;
  maximumTime: number;
  imageUrl?: string;
  slot: string;
  zone: string;
};

const DefaultParkingApp = ({
  occupiedByVehicle = false,
  imageUrl = undefined,
  isEmpty = true,
  remainingTime,
  maximumTime,
  slot,
  zone,
}: DefaultParkingAppProps) => {
  const blue = '#3d5bfe';
  const getHeaderLabel = () => {
    if(!occupiedByVehicle || occupiedByVehicle && remainingTime > 0)
      return 'Parking Allowed';

    return 'In Violation';
  }
  return (
    <Grid
      data-testid="parking-space-container"
      display="flex"
      justifyContent="space-around"
      flexDirection="column"
      alignItems="center"
      spacing="0"
      container
    >
      <ParkingSpaceHeader
        message={getHeaderLabel()}
        backgroundColor={blue}
        occupiedByVehicle={occupiedByVehicle}
      />
      <ParkingSpaceProgress
        isEmpty={isEmpty}
        remainingTime={remainingTime}
        maximumTime={maximumTime}
        imageUrl={imageUrl}
      />
      <ParkingSpaceTimer
        color={blue}
        message={getParkingTimerLabel({ remainingTime })}
        isEmpty={isEmpty}
        remainingTime={remainingTime}
        maximumTime={maximumTime}
      />
      <ParkingSpaceFooter slot={slot} zone={zone} color={blue} />
    </Grid>
  );
};

export default DefaultParkingApp;
