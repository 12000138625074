import { useMemo, useState } from 'react';
import { ParkingSpaceInformation } from '../../types/types';

export type useParkingSpaceProps = {
  url: string;
};

export const useParkingSpaceStream = ({
  url,
}: useParkingSpaceProps): ParkingSpaceInformation | null => {
  const [latestData, setLatestData] = useState<ParkingSpaceInformation | null>(
    null,
  );
  useMemo(() => {
    const sse = new EventSource(url);
    function getRealtimeData(data: any) {
      setLatestData(data);
    }
    sse.onmessage = (e) => getRealtimeData(JSON.parse(e.data));
    sse.onerror = () => {
      sse.close();
    };
    return () => {
      sse.close();
    };
  }, [url]);
  return latestData;
};
