import { isTruthy } from './utils';
import { ParkingSpaceInformationVehicleType } from './types/types';
export const RIDESHARE_ALLOWED_TYPES = [
  ParkingSpaceInformationVehicleType.RIDESHARE,
  ParkingSpaceInformationVehicleType.TAXI,
  ParkingSpaceInformationVehicleType.COMMERCIAL,
];
export const DEFAULT_MAX_TIME_MS = 5 * 60 * 1000;

export function getConfig() {
  return {
    URL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3004',
    AUTH_ENABLED: isTruthy(process.env.REACT_APP_AUTH_ENABLED),
    IS_PLAYWRIGHT: isTruthy(process.env.REACT_APP_IS_PLAYWRIGHT) || false,
  };
}
