import {
  configureStore,
  combineReducers,
  Reducer,
  PreloadedState,
} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { apiBase } from '../features/api/apiBase';
import { getConfig } from '../constants';
import parkingSpaceSlice from '../features/parking/parkingSpaceSlice';

const reducers = {
  [apiBase.reducerPath]: apiBase.reducer,
  parkingSpace: parkingSpaceSlice,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  return combinedReducer(state, action);
};

export const setupStore = (preloadedState: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    // ? show the devTools only in development and in non-playwright mode
    devTools:
      process.env.NODE_ENV === 'development' && !getConfig().IS_PLAYWRIGHT,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([apiBase.middleware, thunk]),
  });
};

export const store = setupStore({});
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export type RootState = ReturnType<typeof combinedReducer>;
