import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

import { Typography, Box } from '@mui/material';

export type ParkingSpeaceFooterProps = {
  slot: string;
  zone: string;
  color: string;
};

const ParkingSpaceFooter = ({ slot, zone, color }: ParkingSpeaceFooterProps) => {
  return (
    <Box display="flex" alignItems="center" fontSize="40px" color={color}>
      <FmdGoodOutlinedIcon fontSize="inherit" color="inherit" />
      <Typography
        fontSize="inherit"
        fontWeight={700}
        color="inherit"
        data-testid="parking-space-footer"
      >
        {' '}
        {`${slot} - ${zone}`}
      </Typography>
    </Box>
  );
};

export default ParkingSpaceFooter;
