let getAccessTokenSilently: any = () => {
  return null;
};
export enum BEARER_TYPES {
  BEARER = 'Bearer',
}

export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: any) => (getAccessTokenSilently = func),
  tokenType: BEARER_TYPES.BEARER,
};
