import DefaultParkingApp from '../components/ParkingSpace/Wrappers/DefaultParkingApp';
import RideshareLoadingApp from '../components/ParkingSpace/Wrappers/RideshareLoadingApp';
import Spinner from '../components/Shared/Spinner';
import NoParkingApp from "../components/ParkingSpace/Wrappers/NoParkingApp";

import { getConfig, RIDESHARE_ALLOWED_TYPES, DEFAULT_MAX_TIME_MS } from '../constants';
import { useParkingSpaceStream } from '../features/api/useParkingSpaceStream';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import { useParams } from 'react-router-dom';
import { ParkingSpaceInformationState } from "../types/types";


const ParkingSpacePage = () => {
  const [parkingSpaceData, setParkingSpaceData] = useState(
    useAppSelector((state) => state.parkingSpace.uuid),
  );
  const [remainingTime, setRemainingTime] = useState(DEFAULT_MAX_TIME_MS);
  const [loaded, setLoaded] = useState(false);

  const { uuid } = useParams();

  const data = useParkingSpaceStream({
    url: `${getConfig().URL}/parkingspace/${uuid}`.replace(' ',''),
  });

  useMemo(() => {
    if (data) {
      setParkingSpaceData(data);
      setRemainingTime(data?.remaining_time);
      setLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((remainingTime: number) => remainingTime - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (!loaded) return <Spinner></Spinner>;

  if (parkingSpaceData?.state === ParkingSpaceInformationState.RIDE_SHARE)
    return (
      <RideshareLoadingApp
        occupiedByVehicle={!!parkingSpaceData.vehicle?.arrived_at}
        remainingTime={remainingTime}
        isEmpty={!parkingSpaceData?.vehicle?.arrived_at}
        maximumTime={parkingSpaceData.max_usage_time}
        imageUrl={parkingSpaceData?.vehicle?.photo}
        slot={parkingSpaceData.space_label}
        zone={parkingSpaceData.zone}
        isAvailableToEnterZone={RIDESHARE_ALLOWED_TYPES.includes(parkingSpaceData?.vehicle?.type)}
      />
    );
  else if (parkingSpaceData?.state === ParkingSpaceInformationState.NO_PARKING)
    return (
      <NoParkingApp
        occupiedByVehicle={!!parkingSpaceData.vehicle?.arrived_at}
        remainingTime={remainingTime}
        maximumTime={parkingSpaceData.max_usage_time}
        imageUrl={parkingSpaceData?.vehicle?.photo}
        slot={parkingSpaceData.space_label}
        zone={parkingSpaceData.zone}
      />
    );

  return (
    <DefaultParkingApp
      occupiedByVehicle={!!parkingSpaceData.vehicle?.arrived_at}
      remainingTime={remainingTime}
      isEmpty={!parkingSpaceData?.vehicle?.arrived_at}
      maximumTime={parkingSpaceData.max_usage_time}
      imageUrl={parkingSpaceData?.vehicle?.photo}
      slot={parkingSpaceData.space_label}
      zone={parkingSpaceData.zone}
    />
  );
};

export default ParkingSpacePage;
