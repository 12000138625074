import { Typography } from '@mui/material';
import { getDurationTime } from '../../../functions';

export type ParkingSpaceTimerProps = {
  isEmpty: boolean;
  remainingTime: number;
  maximumTime: number;
  showViolationInstantly?: boolean;
  message: string;
  showTimer?: boolean;
  color: string;
};

const ParkingSpaceTimer = ({
  showViolationInstantly = false,
  isEmpty = true,
  remainingTime,
  message,
  showTimer= true,
  color
}: ParkingSpaceTimerProps) => {
  return (
    <>
      {((!isEmpty) || showViolationInstantly) && (
        <>
          <Typography
            fontSize="40px"
            fontWeight={700}
            color={color}
            data-testid="parking-space-timer-title"
          >
            {message}
          </Typography>
          {showTimer &&
            <Typography
              fontSize="40px"
              fontWeight={700}
              color={
                remainingTime > 0 && !showViolationInstantly
                  ? '#717370'
                  : '#c31500'
              }
              data-testid="parking-space-timer"
            >
              {getDurationTime(remainingTime)}
            </Typography>
          }
        </>
      )}
    </>
  );
};

export default ParkingSpaceTimer;
