import './App.css';
import { createContext, useMemo, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getDesignTokens, font } from '../theme';
import { Outlet } from 'react-router-dom';

declare global {
  interface Window {
    pendo: any;
  }
}

export const ColorModeContext = createContext({
  toggleColorMode: (mode: PaletteMode) => {
    return mode;
  },
});

function App() {
  const [mode, setMode] = useState<PaletteMode>('light');
  const { palette } = createTheme();
  const theme = useMemo(
    () => createTheme(getDesignTokens(mode, palette)),
    [mode],
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: (mode: PaletteMode) => {
        setMode(mode);
        return mode;
      },
    }),
    [],
  );

  return (
    <HelmetProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <div style={{ fontFamily: font }} className="App">
            <Helmet>
              <title>Core Communities UI</title>
            </Helmet>
            <header className="App-header"></header>
            <Outlet />
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </HelmetProvider>
  );
}

export default App;
