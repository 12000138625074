import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialParkingSpaceState: any = {
  uuid: '',
};

export type SetParkingPayload = {
  uuid: string;
};

const parkingSpaceSlice = createSlice({
  name: 'parkingSpace',
  initialState: initialParkingSpaceState,
  reducers: {
    setParkingUuid(state, action: PayloadAction<SetParkingPayload>) {
      state.uuid = action.payload.uuid;
    },
  },
});

export const { setParkingUuid } = parkingSpaceSlice.actions;
export default parkingSpaceSlice.reducer;

export const selectCurrentUuid = (state: RootState) => state.parkingSpace.uuid;
